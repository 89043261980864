import React from "react";
import {
  CurrencyEuroIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  CheckIcon,
  CloudDownloadIcon,
  ChartPieIcon,
  AdjustmentsIcon,
  ChartBarIcon,
  MailIcon,
} from "@heroicons/react/outline";

import LayoutV2 from "../components/layout-v2";
import FeaturesAlternatingToLeft from "../components/ui-v2/features-alternating-to-left";
import GridWithOffsetIcons from "../components/ui-v2/grid-with-offset-icons";
import HeroAngledImage from "../components/ui-v2/hero-angled-image";
import FeaturesAlternatingToRight from "../components/ui-v2/features-alternating-to-right";
import TeamV2 from "../components/ui-v2/team-v2";
import TeamMember from "../components/ui-v2/team-member";
import BottomCTA from "../components/ui-v2/bottom-cta";
import Testimonials from "../components/testimonials";
import { TrackingSpecs } from "../models/tracking-specs";
import { useState } from "react";
import CTA from "../components/shared/cta";
import Navbar from "../components/navbar";

const features = [
  {
    name: "Disponibilità dei prodotti",
    description:
      "Scopri lo stato di disponibilità dei prodotti concorrenti e pianifica attività di marketing precise.",
    icon: ShoppingCartIcon,
  },
  {
    name: "Tasso di penetrazione e di adozione",
    description:
      "Individua nuove opportunità di vendita e di espansione aziendale.",
    icon: ChartPieIcon,
  },
  {
    name: "Strategia di pricing",
    description:
      "Come posizionano e a quale livello di prezzo vendono i loro prodotti per ogni Paese?",
    icon: CurrencyEuroIcon,
  },
];

const data = {
  HeroAngledImage: {
    title: (
      <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
        <span className='block text-swatch_6 xl:inline'>Monitora </span>
        <span className='block xl:inline'>il mercato e </span>
        <span className='block text-swatch_6 xl:inline'>aumenta </span>
        <span className='block xl:inline'>i tuoi profitti ed i clienti</span>
      </h1>
    ),
    description:
      "Monitora i prezzi dei concorrenti e analizza la tua base clienti per aumentare i profitti, ridurre il CPA ed incrementare il Lifetime Value dei tuoi clienti.",
    cta: "Richiedi una demo",
  },
  GridWithOffsetIcons: {
    title: "Tutto quello che ti serve è a portata di un click",
    description:
      "Dedica il tuo tempo alle attività veramente strategiche e dimenticati le analisi in Excel, Pricyo automatizza il lavoro di raccolta ed analisi dei dati.",
    features: [
      {
        name: "Monitoraggio prezzi",
        icon: CurrencyEuroIcon,
        description:
          "Monitoriamo i prezzi per assicurarti una visione sempre completa del tuo mercato.",
      },
      {
        name: "Alert via mail",
        icon: MailIcon,
        description:
          "Ricevi nella tua email le principali variazioni dei prezzi e disponibilità.",
      },
      {
        name: "Esporta i dati",
        icon: CloudDownloadIcon,
        description:
          "Esporta i dati di Pricyo nel comodo formato CSV con un click.",
      },
      {
        name: "Grafici dedicati",
        icon: ChartPieIcon,
        description:
          "Insight precisi e completi per avere tutte le informazioni necessarie in un batter d’occhio.",
      },
      {
        name: "Storico dei dati",
        icon: ChartBarIcon,
        description:
          "Analizza i trend e scopri come si sono comportati i competitor nel tempo.",
      },
      {
        name: "Soluzione flessibile",
        icon: AdjustmentsIcon,
        description:
          "Scegli il piano basato sulle tue necessità, modificalo quando vuoi.",
      },
    ],
  },
  Testimonials: {
    title: "Brand che ci hanno scelto",
    testimonials: [
      "https://cms.pricyo.com/wp-content/uploads/2021/07/allegrini-logo-nero.png",
      "https://cms.pricyo.com/wp-content/uploads/2021/07/abbazia-novacella-logo.png",
      "https://cms.pricyo.com/wp-content/uploads/2021/07/cantina-terlano-logo-nero.png",
      "https://cms.pricyo.com/wp-content/uploads/2021/07/baglio-pianetto-logo-nero.png",
    ],
  },
  FeaturesAlternatingToLeft: {
    title: "Monitoraggio di prezzi e disponibilità",
    description: `
      Monitoriamo automaticamente i prezzi di vendita e le disponibilità dei prodotti dei concorrenti, senza vincoli di paese, valuta o Ecommerce.
    
      Imposta l’account e poi non dovrai più preoccuparti di nulla, ci penserà Pricyo ad aggiornare costantemente i prodotti monitorati.
    `,
  },
  FeaturesAlternatingToRight: {
    title: "Fidelizza i tuoi clienti con la RFM analysis",
    description:
      "Segmenta i tuoi clienti in base ai loro comportamenti e preferenze di acquisto, per aumentare le vendite ed i profitti.",
  },
  FeaturesAlternatingToLeft2: {
    title: "Controlla i costi e la marginalità di ogni vendita",
    description:
      "Pricyo misura dinamicamente tutte le voci di costo dell’Ecommerce (logistica, prodotto, advertising, personale, ecc.) per mostrarti l’andamento del profitto e indicarti il prezzo corretto per massimizzare la redditività.",
  },
};

const Landing: React.FC = (props): JSX.Element => {
  const [trackingSpecs, setTrackingSpecs] = useState<TrackingSpecs>();
  const [timeSaved, setTimeSaved] = useState<number>();

  return (
    <LayoutV2
      seo={{
        title: "Pricyo per gli ecommerce",
        description: `Monitora i prezzi dei concorrenti e analizza la tua base clienti per aumentare i profitti, ridurre il CPA ed incrementare il Lifetime Value dei tuoi clienti.`,
        metaDesc: `Monitora i prezzi dei concorrenti e analizza la tua base clienti per aumentare i profitti, ridurre il CPA ed incrementare il Lifetime Value dei tuoi clienti.`,
        canonical: `/ecommerce/`
      }}
    >
      <HeroAngledImage
        navbar={<Navbar />}
        title={
          <h1 className='text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
            <span className='block text-swatch_6 xl:inline'>Monitora </span>
            <span className='block xl:inline'>il mercato e </span>
            <span className='block text-swatch_6 xl:inline'>aumenta </span>
            <span className='block xl:inline'>
              i tuoi profitti ed i clienti
            </span>
          </h1>
        }
        description={`Monitora i prezzi dei concorrenti e analizza la tua base clienti per aumentare i profitti, ridurre il CPA ed incrementare il Lifetime Value dei tuoi clienti.`}
      >
        <CTA
          id='CTA-landing_ecommerce-headline-above_the_fold'
          text='Richiedi una demo'
          to='#demo'
        />
      </HeroAngledImage>

      <GridWithOffsetIcons
        title={`Tutto quello che ti serve è a portata di un click`}
        description={`Dedica il tuo tempo alle attività veramente strategiche e dimenticati le analisi in Excel, Pricyo automatizza il lavoro di raccolta ed analisi dei dati.`}
        features={[
          {
            name: "Monitoraggio prezzi",
            icon: CurrencyEuroIcon,
            description:
              "Monitoriamo i prezzi per assicurarti una visione sempre completa del tuo mercato.",
          },
          {
            name: "Alert via mail",
            icon: MailIcon,
            description:
              "Ricevi nella tua email le principali variazioni dei prezzi e disponibilità.",
          },
          {
            name: "Esporta i dati",
            icon: CloudDownloadIcon,
            description:
              "Esporta i dati di Pricyo nel comodo formato CSV con un click.",
          },
          {
            name: "Grafici dedicati",
            icon: ChartPieIcon,
            description:
              "Insight precisi e completi per avere tutte le informazioni necessarie in un batter d’occhio.",
          },
          {
            name: "Storico dei dati",
            icon: ChartBarIcon,
            description:
              "Analizza i trend e scopri come si sono comportati i competitor nel tempo.",
          },
          {
            name: "Soluzione flessibile",
            icon: AdjustmentsIcon,
            description:
              "Scegli il piano basato sulle tue necessità, modificalo quando vuoi.",
          },
        ]}
      />

      <Testimonials
        title='Alcuni brand che hanno scelto Pricyo'
        testimonials={[
          "https://cms.pricyo.com/wp-content/uploads/2021/07/allegrini-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/abbazia-novacella-logo.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/cantina-terlano-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/07/baglio-pianetto-logo-nero.png",
          "https://cms.pricyo.com/wp-content/uploads/2021/12/feudi-san-gregorio.png"
        ]}
      ></Testimonials>

      <FeaturesAlternatingToLeft
        title='Monitoraggio di prezzi e disponibilità'
        description={
          <p>
            Monitoriamo automaticamente i prezzi di vendita e le disponibilità
            dei prodotti dei concorrenti, senza vincoli di paese, valuta o
            Ecommerce. <br />
            <br /> Imposta l’account e poi non dovrai più preoccuparti di nulla,
            ci penserà Pricyo ad aggiornare costantemente i prodotti monitorati.
          </p>
        }
        icon={
          <CurrencyEuroIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/monitoraggio-prezzi-sconti.png'
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7 space-y-2'>
            <li className='inline-flex items-start space-x-2'>
              <span>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </span>

              <span>
                <span className='text-gray-600 font-semibold'>
                  Storico dei dati:
                </span>
                Analizza l’andamento dei prezzi per ogni competitor o singolo
                prodotto.
              </span>
            </li>
            <li className='flex justify-between items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'>
                  Feedback visivo delle offerte migliori:
                </span>
                Ottieni a colpo d’occhio un’indicazione su chi sono gli shop con
                le offerte migliori delle tue.
              </span>
            </li>
            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'>Alert </span> via
                email per le principali variazioni.
              </span>
            </li>
          </ul>
          <CTA
            id='CTA-landing_ecommerce-price_tracking'
            text='Richiedi una demo'
            to='#demo'
          />
        </div>
      </FeaturesAlternatingToLeft>

      <FeaturesAlternatingToRight
        title='Fidelizza i tuoi clienti con la RFM analysis'
        description={`
          Segmenta i tuoi clienti in base ai loro comportamenti e preferenze di acquisto, per aumentare le vendite ed i profitti.
        `}
        icon={
          <ShieldCheckIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/presentazione-brand.png '
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7'>
            <li className='inline-flex items-center space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'></span>
                Pianifica campagne ed attività di marketing e di reingaggio
                mirate <span className='font-semibold'>ad alto ROI.</span>
              </span>
            </li>

            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='text-gray-600 font-semibold'></span>
                Scopri quali sono le attività che portano{" "}
                <span className='font-semibold'>risultati</span> e valore e
                quali invece erodono la redditività aziendale.
              </span>
            </li>
            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='font-semibold'>
                  Aumenta il Lifetime Value (LTV)
                </span>
                dei tuoi clienti
              </span>
            </li>
            <li className='inline-flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                <span className='font-semibold'>
                  Riduci il Costo di Acquisizione
                </span>
                (CPA) delle tue campagne di advertising
              </span>
            </li>
          </ul>
          <CTA id='CTA-landing_ecommerce-rfm' text='Scopri di più' to='#demo' />
        </div>
      </FeaturesAlternatingToRight>

      <FeaturesAlternatingToLeft
        title='Controlla i costi e la marginalità di ogni vendita'
        description={`
          Pricyo misura dinamicamente tutte le voci di costo dell’Ecommerce (logistica, prodotto, advertising, personale, ecc.) per mostrarti l’andamento del profitto e indicarti il prezzo corretto per massimizzare la redditività.
        `}
        icon={
          <ShoppingCartIcon className='h-6 w-6 text-white' aria-hidden='true' />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/pricyo-screen-disponibilità-2.png'
      >
        <div className='space-y-3'>
          <ul className='text-swatch_7'>
            <li className='inline-flex items-start space-x-2'>
              <span>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </span>

              <span>
                Scopri quali sono i canali di marketing ed i clienti{" "}
                <span className='font-semibold'>più profittevoli.</span>
              </span>
            </li>
            <li className='flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                Individua i tuoi{" "}
                <span className='font-semibold'>migliori prodotti</span> per
                vendite e profitti
              </span>
            </li>
            <li className='flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                {" "}
                Stabilisci il giusto prezzo per
                <span className='font-semibold'>
                  battere i tuoi concorrenti
                </span>{" "}
                e acquisire nuovi clienti
              </span>
            </li>
            <li className='flex items-start space-x-2'>
              <div>
                <CheckIcon className='w-6 h-6 text-green-500' />
              </div>
              <span>
                {" "}
                Pianifica
                <span className='font-semibold'>
                  attività promozionali più efficaci
                </span>{" "}
                più efficaci.
              </span>
            </li>
          </ul>
          {/* <CTA text='Richiedi una demo' to='#demo' /> */}
        </div>
      </FeaturesAlternatingToLeft>

      <FeaturesAlternatingToRight
        title='Esporta i dati'
        description={`
          Integra i dati Pricyo con altri tuoi strumenti di analisi. 
          Scarica il report di tuo interesse nel comodo formato CSV.
        `}
        icon={
          <CloudDownloadIcon
            className='h-6 w-6 text-white'
            aria-hidden='true'
          />
        }
        image='https://cms.pricyo.com/wp-content/uploads/2021/08/pricyo-export.png'
      >
        <CTA
          id='CTA-landing_ecommerce-export_dati'
          text='Richiedi una demo'
          to='#demo'
        />
      </FeaturesAlternatingToRight>

      <TeamV2
        id='tema'
        title='Il Team di Pricyo'
        description={`Siamo Maker, realizziamo soluzioni ad alto contenuto tecnologico
              al servizio delle aziende. Pricyo è nato per aiutare i produttori
              e i rivenditori a monitorare il panorama distributivo e
              competitivo online e prendere migliori decisioni di business.`}
      >
        <TeamMember
          name='Edoardo Maria Erriu'
          role='CEO'
          description='Gestisce la strategia web e la pubblicità per Ecommerce e Brand sul mercato domestico ed estero.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/edoardo-maria-erriu-web-marketing-1-e1605307748764.jpg'
        ></TeamMember>
        <TeamMember
          name='Davide Sicignani'
          role='CIO'
          description='Ha realizzato il primo software di monitoraggio per un investitore di opere d’arte, monitorando i principali siti d’aste europei.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/davide-sicignani-web-developer.jpg'
        ></TeamMember>
        <TeamMember
          name='Ugo Buonadonna'
          role='CTO'
          description=' Lavora su interfacce e algoritmi con enormi moli di dati e
                    metodi per estrapolare efficacemente informazioni con scopi di business.'
          imageUrl='https://cms.pricyo.com/wp-content/uploads/2020/11/ugo.jpeg'
        ></TeamMember>
      </TeamV2>

      <BottomCTA
        id='demo'
        title='La suite di cui hai bisogno per scalare il tuo Ecommerce'
        description={
          <span>
            Un unico strumento per analizzare i concorrenti ed i tuoi clienti e
            prendere decisioni di business data-driven. Fai crescere il tuo
            Ecommerce, risparmiando tempo e con meno stress.
          </span>
        }
        features={[
          "Monitoraggio dei prezzi",
          "Alert in tempo reale",
          "Report dedicati",
          "Analisi dei clienti",
          "Export dei dati",
          "Storico dei dati",
          "Crea audience per il marketing",
          "Risparmia tempo e soldi",
        ]}
        leadsTags={[{ name: "Richiedi demo" }, { name: "Demo ecommerce" }]}
        gtmEvent='Form lead ecommerce'
      ></BottomCTA>
    </LayoutV2>
  );
};

export default Landing;
